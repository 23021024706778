.img-background {
    background-color: rgba(0, 0, 0, 0.8);
    background-blend-mode: darken;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.home-text {
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 5rem;
}

.footer-style {
    background-color: lightgray;
    font-weight: 400;
}

.sdfkjsd {
    background-color: #000000;
    background-color: #000000;
    background-color: #000000;
    background-color: #000000cc;
    background-color: #00000080;
    background-color: #0000004d;
    background-color: #EAEAEA;
    background-color: #EAEAEAb3;
    background-color: #ffffffba;
    background-color: #929292;
    background-color: #212121cc;
    background-color: #212121b3;
    background-color: #21212180;
}
