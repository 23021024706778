@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Quicksand:wght@300;400;500;600;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
body {
    margin: 0;
    font-family: "Quicksand", "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
    font-weight: 500 !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace !important;
}


* {
  margin: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}


/* scrollBar */
/*
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}
::-webkit-scrollbar-track {
    background: #555;
}
::-webkit-scrollbar-thumb {
    background: #555;
  border-radius: 10px;
  transition: 0.3s;
}

img{
  -webkit-touch-callout: none; 
    -webkit-user-select: none;
     -khtml-user-select: none; 
       -moz-user-select: none;
        -ms-user-select: none; 
            user-select: none; 

}
*/

@media screen and (max-width: 400px) {
  body {
    overflow-x: hidden;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 
  480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {
    body {
      overflow-x: hidden;
    }
}

.img-background {
    background-color: rgba(0, 0, 0, 0.8);
    background-blend-mode: darken;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.home-text {
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 5rem;
}

.footer-style {
    background-color: lightgray;
    font-weight: 400;
}

.sdfkjsd {
    background-color: #000000;
    background-color: #000000;
    background-color: #000000;
    background-color: #000000cc;
    background-color: #00000080;
    background-color: #0000004d;
    background-color: #EAEAEA;
    background-color: #EAEAEAb3;
    background-color: #ffffffba;
    background-color: #929292;
    background-color: #212121cc;
    background-color: #212121b3;
    background-color: #21212180;
}

.skills {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 60vh;
    padding: 2.5rem 2rem 2rem 2rem;
}

.skillsHeader {
    display: flex;
    align-items: center;
    justify-content: center; 
}

.skillsHeader h2 {
    font-family: var(--primaryFont);
    font-style: normal;
    font-weight: bold;
    font-size: 3.5rem;
    text-align: center;
}

.skillsContainer {
    display: flex;
    align-items: center;
    justify-content: center; 
    width: 100%;
    margin-top: 3.5rem;
    padding: 0 2rem;
}

.skill--scroll {
    width: 100%;
    margin: 0 2rem;
}


.skill--box {
    background: #FAFAFA;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    width: 160px;
    height: 160px;
    margin: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem 1rem;
    transition: 300ms ease-in-out;
}

.skill--box:hover  {
    -webkit-transform: scale(1.15);
            transform: scale(1.15);
}


.skill--box > img {
    height: 50px;
    pointer-events: none;
}


.skill--box h3 {
    font-family: Big Shoulders Text;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    text-align: center;
    margin-top: 1rem;
}


.marquee {
    padding: 3rem 0;
}


@media (min-width: 992px) and (max-width: 1380px) {
    .skills {
        padding: 2rem 1rem 1rem 1rem;
    }
    .skillsContainer {
        padding: 1.5rem;
        margin-top: 1.5rem;
    }
}


@media screen and (max-width: 992px) {
    .skills {
        padding: 1rem;
        min-height: 100%;
    }
    .skillsContainer {
        padding: 1rem;
        margin: 1rem 0;
    }
    .skillsHeader h2 {
        font-size: 3.2rem;
    }
    .skill--box {
        width: 150px;
        height: 150px;
        margin: 1.2rem;
        padding: 2rem 1rem;
    }

    .skill--box > img {
        height: 45px;
    }


    .skill--box h3 {
        font-size: 20px;
        margin-top: 1rem;
    }

}

@media screen and (max-width: 800px) {
    .skills {
        padding: 0.5rem;
    }
    .skillsContainer {
        padding: 0.5rem;
    }
    .skillsHeader h2 {
        font-size: 3rem;
    }

    .skill--scroll {
        width: 100%;
        margin: 0;
    }
}

@media screen and (max-width: 600px) {
    .skill--box {
        width: 135px;
        height: 135px;
        margin: 1.2rem;
        padding: 2rem 1rem;
    }

    .skill--box > img {
        height: 40px;
    }

    .skillsHeader h2 {
        font-size: 2.5rem;
    }

    .skill--box h3 {
        font-size: 18px;
        margin-top: 1rem;
    }
}

@media screen and (max-width: 400px) {

}
  
@media only screen and (min-device-width: 320px) and (max-device-width: 
  480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {

}


@media only screen and (device-width: 768px) {

}
.landing {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.landing--container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
}

.landing--img {
  --img-size: 400px;
  position: absolute;
  left: 35%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: var(--img-size);
  height: var(--img-size);
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s;
}

.landing--container-left {
  flex: 35% 1;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.lcl--content {
  margin: 3rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.landing--social {
  font-size: 35px;
  margin: 0 1rem;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}

.landing--social:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.landing--container-right {
  flex: 65% 1;
  height: 100%;
  background-color: #eaeaea;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.lcr--content {
  width: 45%;
  margin-right: 7rem;
  font-family: var(--primaryFont);
  font-style: normal;
}

.lcr--content h6 {
  font-weight: 500;
  font-size: 1.25rem;
  margin-bottom: -0.85rem;
  opacity: 0.8;
}

.lcr--content h1 {
  font-weight: 600;
  font-size: 3.25rem;
  line-height: 110%;
  margin: 1rem 0;
}

.lcr--content p {
  margin-top: 1.45rem;
  font-weight: 500;
  font-size: 1.15rem;
  opacity: 0.7;
}

.lcr-buttonContainer {
  margin-top: 2rem;
  width: 350px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "var(--primaryFont)", sans-serif;
}

@media (max-width: 1100px) {
  .landing--img {
    --img-size: 350px;
  }
  .lcl--content {
    margin: 2rem;
  }

  .lcr--content h6 {
    font-size: 1.1rem;
  }

  .lcr--content h1 {
    font-size: 3rem;
  }

  .lcr--content p {
    margin-top: 0.3rem;
    margin-bottom: 1rem;
    font-weight: 500;
    font-size: 1rem;
  }

  .lcr-buttonContainer {
    margin-top: 1rem;
    flex-direction: column;
    width: 200px;
    height: 120px;
    align-items: flex-start;
  }
}

@media (max-width: 900px) {
  .landing--img {
    --img-size: 300px;
  }

  .landing--social {
    font-size: 30px;
  }
  .lcr--content {
    width: 60%;
    margin-top: 10%;
    margin-right: 6%;
  }

  .lcr-buttonContainer {
    margin-top: 1rem;
    flex-direction: column;
    width: 200px;
    height: 120px;
    align-items: flex-start;
  }
  .lcr-buttonContainer button:nth-child(2) {
    display: none;
  }
}

@media (max-width: 600px) {
  .landing--container {
    flex-direction: column;
    justify-content: space-between;
  }
  .landing--img {
    --img-size: 200px;
    left: 50%;
    top: 30%;
    border: 8px solid #eaeaea;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  .landing--container-left {
    flex: initial;
    width: 100%;
    height: 30%;
  }
  .landing--social {
    display: none;
  }
  .landing--container-right {
    box-sizing: border-box;
    padding: 0 2rem;
    flex: initial;
    height: 70%;
    width: 100%;
    background-color: #eaeaea;
    align-items: center;
    justify-content: center;
    padding: 0 2rem;
  }
  .lcr--content {
    margin-top: 20%;
    margin-right: 0;
    margin-right: initial;
    width: 100%;
  }
  .lcr--content h6 {
    font-size: 1rem;
    text-align: center;
  }
  .lcr--content h1 {
    font-size: 2.5rem;
    text-align: center;
  }
  .lcr--content p {
    font-size: 0.95rem;
    text-align: center;
    margin: 0 auto;
    width: 90%;
  }
  .lcr-buttonContainer {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .lcr-buttonContainer button {
    margin-top: -20%;
  }
}

@media (max-width: 500px) {
  .lcr--content {
    margin-top: 35%;
  }
}
@media (max-width: 370px) {
  .lcr--content {
    margin-top: 60%;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {
  .landing {
    height: 100%;
  }

  .landing--img {
    --img-size: 150px;
    left: 50%;
    top: 20%;
  }
}

.education {
    min-height: 75vh;
}

.education-body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: hsla(117, 56%, 49%, 0.702);
}

.education-description {
    width: 100%;
    flex: 0.65 1;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 7%;
}

.education-description > h1 {
    font-size: 3.5rem;
    font-family: var(--primaryFont);
    margin-bottom: 2rem;
}

.education-card {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 90%;
    height: 140px;
    padding: 1.5rem;
    border-radius: 20px;
    margin-bottom: 1.5rem;
    transition: background-color 200ms ease-in-out;
}

.educard-img {
    border-radius: 50%;
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.educard-img > img {
    width: 40px;
}

.education-details {
    margin-left: 0.6rem;
}

.education-details > h6 {
    font-family: var(--primaryFont);
    font-size: 0.85rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
}

.education-details > h4 {
    font-family: var(--primaryFont);
    font-size: 1.225rem;
    font-weight: 600;
}

.education-details > h5 {
    font-family: var(--primaryFont);
    font-size: 1.15rem;
    font-weight: 600;
}

.education-image {
    box-sizing: border-box;
    padding: 1rem;
    flex: 0.35 1;
    margin-top: 1rem;
    pointer-events: none;
}

.education-image > img {
    width: 100%;
}

@media (min-width: 992px) and (max-width: 1380px) {
}

@media screen and (max-width: 992px) {
    .education {
        min-height: 100%;
    }
    .education-image {
        display: none;
    }
    .education-description {
        flex: 1 1;
        margin: auto;
    }
    .education-description > h1 {
        font-size: 3.2rem;
    }
    .education-card {
        width: 100%;
        padding: 1.5rem;
        margin-bottom: 1.5rem;
    }
}

@media screen and (max-width: 800px) {
    .education-description > h1 {
        font-size: 3rem;
        margin-bottom: 2rem;
        align-self: center;
    }
    .education-description {
        padding: 1rem;
    }
    .education-card {
        padding: 1rem;
        margin-bottom: 1.5rem;
        height: 130px;
    }
    .educard-img {
        border-radius: 50%;
        width: 45px;
        height: 45px;
    }

    .educard-img > img {
        width: 30px;
    }
    .education-details > h6 {
        font-size: 0.85rem;
        margin-bottom: 0.45rem;
    }

    .education-details > h4 {
        font-size: 1.125rem;
        line-height: 126%;
    }

    .education-details > h5 {
        font-size: 1.05rem;
        line-height: 126%;
    }
}

@media screen and (max-width: 600px) {
    .education-description > h1 {
        font-size: 2.5rem;
    }
}

@media screen and (max-width: 400px) {
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {
}

@media only screen and (device-width: 768px) {
}

.projects {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 100vh;
    overflow-x: hidden;
    padding: 2rem;
}


.projects--header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 1rem;
}


.projects--header h1 {
    margin-bottom: 40px;
    font-size: 3.5rem;
    font-family: var(--primaryFont);
}

.projects--body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.projects--bodyContainer {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    align-items: center;
    justify-content: center;
    grid-gap: 2.5rem;
    width: 90%;
    margin: auto;
}

.projects--viewAll {
    width: 100%;
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 2rem;
}

.projects--viewAll a button {
    outline: none;
    border: none;
    width: 150px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: inherit;
    border-radius: 45px;
    font-size: 1.05rem;
    font-family: var(--primaryFont);
    font-weight: 500;
    padding-left: 1.5rem;
    cursor: pointer;
}



@media (min-width: 992px) and (max-width: 1380px) {

}


@media screen and (max-width: 1200px) {
    .projects--bodyContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
}

@media screen and (max-width: 800px) {
    .projects--header h1 {
        font-size: 3rem;
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 600px) {
    .projects--header h1 {
        font-size: 2.5rem;
        margin-bottom: 20px;
    }
    .projects--viewAll {
        position: relative;
        margin-top: 4rem;
    }
    .projects--viewAll a {
        position: absolute;
        left: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);  
    }
}

@media screen and (max-width: 400px) {

}
  
@media only screen and (min-device-width: 320px) and (max-device-width: 
  480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {

}


@media only screen and (device-width: 768px) {

}
.singleProject {
    box-shadow: 4px 4px 8px rgba(36, 3, 3, 0.2);
    width: 550px;
    /* width: 95%; */
    margin: auto;
    height: 460px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.4rem 1rem;
    position: relative;
    cursor: pointer;
    overflow: hidden;
}

.projectContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.projectContent > h2 {
    font-family: var(--primaryFont);
    font-style: normal;
    font-weight: 600;
    font-size: 1.525rem;
    line-height: 110%;
    text-align: center;
}

.projectContent > img {
    width: 100%;
    height: 60%;
    transition: opacity 0.7s 0.3s;
}

.singleProject:hover img{
    opacity: 0;
}

.project--showcaseBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.project--showcaseBtn > a {
    cursor: pointer;
    text-decoration: none;
    transition: -webkit-transform 0.5s 0.3s;
    transition: transform 0.5s 0.3s;
    transition: transform 0.5s 0.3s, -webkit-transform 0.5s 0.3s;
}

.singleProject:hover .project--showcaseBtn a:nth-child(2) {
    -webkit-transform: translateX(-140px) scale(1.1) !important;
            transform: translateX(-140px) scale(1.1) !important;
}


.singleProject .project--desc{
    position: absolute;
    width: 95%;
    height: 200px;
    left: 0;
    top: 0;
    -webkit-transform: translate(-110%,40%);
            transform: translate(-110%,40%);
    padding: 15px;
    border-radius: 0 20px 20px 0;
    transition: -webkit-transform 0.9s;
    transition: transform 0.9s;
    transition: transform 0.9s, -webkit-transform 0.9s;
    line-height: 110%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--primaryFont);
}
  
.singleProject:hover .project--desc{
    -webkit-transform: translate(-2%,40%);
            transform: translate(-2%,40%);
}
  
.singleProject .project--lang{
    position: absolute;
    bottom: 20px;
    right: 0;
    width: 140px;
    font-size: 0.8rem;
    display: flex;
    justify-content: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    flex-direction: column;
    border-radius: 10px 0 0 10px;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    transition: -webkit-transform 0.5s 0.3s;
    transition: transform 0.5s 0.3s;
    transition: transform 0.5s 0.3s, -webkit-transform 0.5s 0.3s;
    padding: 0.825rem;
}
  
.singleProject:hover .project--lang{
    -webkit-transform: translateX(0);
            transform: translateX(0);
}

.project--lang > span {
    font-family: var(--primaryFont);
    font-weight: 500;
    word-break: break-word; 
    line-height: 100%;
}

@media screen and (max-width: 600px) {
    .singleProject{
        width: 300px;
    }
}
.achievement {
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 3rem;
}

.achievement-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    flex: 0.4 1;
    width: 90%;
}

.achievement-body h1 {
    font-family: var(--primaryFont);
    font-size: 3.5rem;
    margin-bottom: 1.5rem;
}

.achievement-body h4 {
    font-size: 1.75rem;
    font-family: 'Caveat', cursive;
    font-weight: 400;
}

.achievement-cards {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 5%;
    width: 100%;
    flex: 0.6 1;
}

.achievement-card {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    height: 170px;
    padding: 1.5rem;
    border-radius: 20px;
    margin-bottom: 1.5rem;
    margin-left: 1.5rem;
    transition: background-color 200ms ease-in-out;
    width: 100%;
    position: relative;
}

.achievecard-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    width: 65%;
   
}

.achievecard-details2 {
    display: flex;
    flex-direction: row;
    width: 50%;
    align-items: center;
    font-family: var(--primaryFont);
    font-size: 18px;
    position: absolute;
    bottom: 7px;
    left: 25px;
}

.achievecard-details2 h5 {
   width: 50%;
}

.achievecard-field {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.achievecard-field svg {
    margin-right: 6px;
}

.achievecard-details1 h2 {
    font-family: var(--primaryFont);
    font-weight: 500;
    font-size: 1.225rem;
    line-height: 110%;
    margin-bottom: 6px;
}

.achievecard-details1 p {
    font-size: 1.10rem;
    font-family: Roboto;
    font-weight: 400;
}

.achievecard-imgcontainer {
    width: 23%;
}

.achievecard-imgcontainer > img {
    width: 150px;
    height: 130px;
    border-radius: 10px;
}

@media (min-width: 992px) and (max-width: 1380px) {
    .achievement-card {
        justify-content: flex-start;
    }

    .achievement-body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-evenly;
        flex: 0.3 1;
        width: 90%;
    }
    .achievement-body h1 {
        font-family: var(--primaryFont);
        font-size: 3.1rem;
    }
    
    .achievement-cards {
        padding: 1rem;
        margin-left: 1%;
        width: 100%;
        flex: 0.7 1;
    }
    .achievecard-imgcontainer {
       margin-left: 1rem;
    }

}

@media screen and (max-width: 992px) {
    .achievement{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        padding: 2rem;
    }


    .achievement-body {
        width: 100%;
    }

    .achievement-body h1 {
        font-size: 2.5rem;
    }

    .achievement-cards {
        margin-left: 0%;
        padding: 1rem 0;
        width: 80%;
        margin-top: 1rem;
    }

    .achievement-card {
        margin-left: 0px;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }

    .achievecard-content {
        width: 70%;
    }

    .achievecard-details1 {
        margin-bottom: 20px;
    }

    .achievecard-details1 h2 {
        font-size: 1.15rem;
    }

    .achievecard-details1 p {
        font-size: 0.95rem;
    }

    .achievecard-details2 {
        width: 85%;
        position: absolute;
        bottom: 7px;
        left: 25px;
    }

    .achievecard-imgcontainer {
        width: 30%;
        display: flex;
       justify-content: center;
    }

    .achievecard-imgcontainer > img {
        width: 80px;
        height: 70px;
    }
    
}

@media screen and (max-width: 600px) {
    .achievement{
        padding: 1rem;
    }
    .achievement-cards {
        width: 100%;
    }
}

@media screen and (max-width: 600px) {

    .achievement-body h4 {
        font-size: 1.5rem;
    }

    .achievecard-details1 p {
        font-size: 0.95rem;
    }
    .achievecard-imgcontainer > img {
        width: 70px;
        height: 60px;
    }

}


.experience{
    min-height: 100vh;
}

.experience-body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.experience-description {
    width: 100%;
    flex: 0.65 1;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    margin-right: 2rem;
}

.experience-description > h1 {
    font-size: 3.5rem;
    font-family: var(--primaryFont);
    margin-bottom: 2rem;
}

.experience-card {
    display: flex;
    flex-direction: row;
    align-items:flex-start;
    justify-content: flex-start;
    width: 90%;
    height: 140px;
    padding: 1.5rem;
    border-radius: 20px;
    margin-bottom: 1.5rem;
    transition: background-color 200ms ease-in-out;
}

.expcard-img {
    border-radius: 50%;
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.expcard-img > img {
    width: 36px;
    pointer-events: none;
}

.experience-details {
    margin-left: 0.6rem;
}

.experience-details > h6 {
    font-family: var(--primaryFont);
    font-size: 0.85rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
}

.experience-details > h4 {
    font-family: var(--primaryFont);
    font-size: 1.225rem;
    font-weight: 600;
}

.experience-details > h5 {
    font-family: var(--primaryFont);
    font-size: 1.15rem;
    font-weight: 600;
}

.experience-image {
    box-sizing: border-box;
    padding: 1rem;
    flex: 0.35 1;
    margin-left: 7%;
    margin-top: 1rem;
    pointer-events: none;
}

.experience-image > img {
    width: 100%;
    pointer-events: none;
}






@media (min-width: 992px) and (max-width: 1380px) {

}


@media screen and (max-width: 992px) {
    .experience {
        min-height: 100%;
    }
    .experience-image {
        display: none;
    }
    .experience-description {
        flex: 1 1;
        margin: auto;
    }    
    .experience-description > h1{
        font-size: 3.2rem;
    }
    .experience-card {
        width: 100%;
        padding: 1.5rem;
        margin-bottom: 1.5rem;
    }
}

@media screen and (max-width: 800px) {
    .experience-description > h1{
        font-size: 3rem;
        margin-bottom: 2rem;
        align-self: center;
    }
    .experience-description {
        padding: 1rem;
    }
    .experience-card {
        padding: 1rem;
        margin-bottom: 1.5rem;
        height: 130px;
    }
    .expcard-img {
        border-radius: 50%;
        width: 45px;
        height: 45px;
    }
    
    .expcard-img > img {
        width: 30px;
    }
    .experience-details > h6 {
        font-size: 0.85rem;
        margin-bottom: 0.45rem;
    }
    
    .experience-details > h4 {
        font-size: 1.125rem;
        line-height: 126%;
    }
    
    .experience-details > h5 {
        font-size: 1.05rem;
        line-height: 126%;
    }
}

@media screen and (max-width: 600px) {
    .experience-description > h1{
        font-size: 2.5rem;
    }
}

@media screen and (max-width: 400px) {

}
  
@media only screen and (min-device-width: 320px) and (max-device-width: 
  480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {

}


@media only screen and (device-width: 768px) {

}
.backToTop {
    position: fixed; 
    right: 10px;
    bottom: 60px;
    height: 30px;
    font-size: 3rem;
    z-index: 999;
}

.backToTop button {
    outline: none;
    border: none;
    cursor: pointer;
    background: none;
    padding: 20px;
}

@media screen and (max-width: 800px) {
    .backToTop { 
        right: -10px;
        bottom: 50px;
        font-size: 2.75rem;
    }
}
